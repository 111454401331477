import React, { FormEvent, useState } from "react";
import "./EmailInput.css";
import { trackClick } from "../lib/analytics";

async function postEmailToWaitlist(email: string) {
  await fetch("https://widgets-6n74tt3wra-uc.a.run.app/waitlist", {
    method: "POST", // Specifies the method
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
}

const EmailInput = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackClick("home", "join waitlist");
    await postEmailToWaitlist(email);
    // Handle the email submission logic here
    console.log("Email submitted:", email);
    setEmail("");
  };

  return (
    <form onSubmit={handleSubmit} className="email-form">
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Your email..."
        className="email-input"
      />
      <button type="submit" className="submit-btn semi-bold">
        Join waitlist
      </button>
    </form>
  );
};

export default EmailInput;
