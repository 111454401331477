import React, { useEffect } from "react";
import "./App.css";
import commitIcon from "./commit_icon.png";
import EmailInput from "./Components/EmailInput";
import { trackPageViewed } from "./lib/analytics";

function TeaserImage() {
  return (
    <div>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/commit-b604f.appspot.com/o/landing_page_explainer_goal_setup.png?alt=media&token=06ef09aa-656d-432a-9b01-15f77707fb4f"
        className="landing-page-explainer img-goal-setup"
        alt="landing page explainer"
      />
      <img
        src="https://firebasestorage.googleapis.com/v0/b/commit-b604f.appspot.com/o/landing_page_explainer_goal_in_progress.png?alt=media&token=27c65e28-8d72-4e50-87e0-1d1a42fb3780"
        className="landing-page-explainer img-goal-in-progress"
        alt="landing page explainer"
      />
    </div>
  );
}

function AppExplainerText() {
  return (
    <div className="app-explainer-container">
      {/* <h2 className="semi-bold no-margin">Place a bet on your health.</h2> */}
      <div className="app-icon-container">
        <img src={commitIcon} alt="app icon" />
      </div>
      <h2 className="medium no-margin">A win-win wager for your wellness.</h2>
      <p className="no-margin">Invest in your most important asset.</p>
      <EmailInput />
    </div>
  );
}

function App() {
  useEffect(() => {
    trackPageViewed("home");
  }, []);
  return (
    <div className="app">
      <header className="app-header">
        <TeaserImage />
        <AppExplainerText />
      </header>
      {/* <div>
        <p className="no-margin center-text">FAQ: why increase vo2 max?</p>
      </div> */}
    </div>
  );
}

export default App;

// START
// [x] 0. find steps to get domain + web app hosted on firebase.
// [x] 1. get hosted
// [x] 2. get domain setup
// [x] 3. YC app. including bullet responses to vid questions
// [x] 4. YC app: record video
// [x] review, edit, submit YC App

// [x] add analytics

// [x] move images to storage, not hosting
// [x] copy update on site
// [ ] add waitlist
// [ ]  add activity text to screenshots
// [ ]  mvp mobile optimize app
// [ ] add/remove FAQ

// YC vid:
// - hi YC I'm Liam and I'm working on commit, where we're helping people improve their wellness using financial commitments.
// This product comes from my experience with losing a lot of weight and maintianing that weightloss. Over the past few years, I've been paying for a 3rd party to track my body compositon -- things like body fat, muscle mass; this commitment, along with its data, helped me see how my everyday decisions built up to my health goals.
// this is a small example of a bigger picture in the health space– Americans generally treat their finances with higher regard than their healh
// so we can use financial mechanism to help people shift their behavior towards positive health outcomes. for example, a product like a "401k for your body".
// - in terms of background - I'm a software engineer with ... how can we take the interest that people have in finance (meme stocks, crypto hype) and use to tangibly impact ppl's daily lives
// We see increasing amounts of the lifestyle diseases in the US, and people underestimate their ability to make positive changes for their health. They also underestimate their positive impact of these changes.
// Also the health market is massive. So there's a large opporunity to unlock people's individual ability to change and move away from over-reliance of expensive, less effective solutions.
//...product -> include my experience with weight and IBD ... with weight – committing to DEXASCANS over the past two years has helped me lose and maintain a healthy body composition

// 401k for your body. mortage for your health.
//... quick about me and then product ...

// ....product: existing systems leave people feeling like they can't make the changes themselves.

// tips:
// 1. why will your solution beat out the existing ones?
// 2. use plain english, no buzzwords
//
