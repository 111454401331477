import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAuzdBZaiRWFOy1h90Fw3wfsLfe8m-YQOI",
  authDomain: "commit-b604f.firebaseapp.com",
  projectId: "commit-b604f",
  storageBucket: "commit-b604f.appspot.com",
  messagingSenderId: "375595782367",
  appId: "1:375595782367:web:810b8c058b18fc8bded96a",
  measurementId: "G-C874E6MVWQ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const EVENTS = {
  PAGE_VIEWED: "page_viewed",
  BUTTON_CLICKED: "button_clicked",
};

export function track(eventName: string, props: object) {
  logEvent(analytics, eventName, props);
}

export function trackPageViewed(pageName: string) {
  track(EVENTS.PAGE_VIEWED, { pageName });
}

export function trackClick(pageName: string, buttonName: string) {
  track(EVENTS.BUTTON_CLICKED, { pageName, buttonName });
}
